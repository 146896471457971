/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import '../src/styles/index.scss'
import LoadingIndicator from '../src/components/common/Layout/LoadingIndicator'
// import '../src/lib/antd/antd.css'

const MyApp = (props) => {
  const { Component, pageProps } = props
  return (
    <>
      <Head>
        <title>Simsmart</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <title>Simsmart</title>
        <meta name="description" content="Chuyên trang cung cấp các thiết bị thông minh, có sử dụng dịch vụ viễn thông, sim số… giúp cho người dùng quản lý một cách dễ dàng, tiện lợi và nhanh chóng các thiết bị thông minh trong gia đình." />
        <meta itemProp="name" content="Simsmart - Giải pháp thiết bị thông minh và nhiều hơn thế" />
        <meta itemProp="description" content="Chuyên trang cung cấp các thiết bị thông minh, có sử dụng dịch vụ viễn thông, sim số… giúp cho người dùng quản lý một cách dễ dàng, tiện lợi và nhanh chóng các thiết bị thông minh trong gia đình." />
        <meta name="twitter:title" content="Simsmart - Giải pháp thiết bị thông minh và nhiều hơn thế" />
        <meta name="twitter:description" content="Chuyên trang cung cấp các thiết bị thông minh, có sử dụng dịch vụ viễn thông, sim số… giúp cho người dùng quản lý một cách dễ dàng, tiện lợi và nhanh chóng các thiết bị thông minh trong gia đình." />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:title" content="Simsmart - Giải pháp thiết bị thông minh và nhiều hơn thế" />
        <meta property="og:description" content="Chuyên trang cung cấp các thiết bị thông minh, có sử dụng dịch vụ viễn thông, sim số… giúp cho người dùng quản lý một cách dễ dàng, tiện lợi và nhanh chóng các thiết bị thông minh trong gia đình." />

        <meta
          property="og:image"
          content="https://simsmart.vn/images/common/simsmart-favicon.png"
        />

        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:url" content="https://simsmart.vn" />

        <meta name="theme-color" />
        <link rel="shortcut icon" href="/images/common/simsmart-favicon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
      </Head>
      <LoadingIndicator />
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(MyApp)
