import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { hide, show } from './topbar'

const LoadingIndicator = () => {
  const router = useRouter()

  useEffect(() => {
    const handleStart = (url) => {
      if (router.pathname !== '/sim-list' || !url.includes('sim-list')) {
        show()
      }
    }

    const handleComplete = (e) => {
      hide()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [router])

  return null
}

export default LoadingIndicator
